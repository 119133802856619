import { useEffect, useState } from "react"
import EditeService from "./EditeService"
import DeleteDataComponent from "../../components/DeleteDataComponent"




export default function ShowService({ ApiLink }) {

    const [serveice, setService] = useState([])

    useEffect(() => {
        fetch(`${ApiLink}/servicesection/showservice`)
            .then((res) => res.json())
            .then((data) => { setService(data) })

    }, [])




    return (

        <section className="mt-16">

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-lg text-center  text-gray-500 dark:text-gray-400">
                    <thead className="text-white uppercase bg-[#00BF62] ">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                id
                            </th>
                            <th scope="col" className="px-6 py-3">
                                اسم الخدمه
                            </th>
                            <th scope="col" className="px-6 py-3">
                                الصوره
                            </th>
                            <th scope="col" className="px-6 py-3">

                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            serveice.map((item, index) => (
                                <tr className="bg-[#212121] border-[#ffffff4a] border-b-2" key={index}>
                                    <th scope="row" className=" py-4 font-medium text-white whitespace-nowrap">
                                        {index + 1}
                                    </th>
                                    <th scope="row" className=" py-4 font-medium text-white whitespace-nowrap">
                                        {item.title}
                                    </th>
                                    <td className=" py-4 text-white">
                                        <img src={item.image} className="w-[150px] h-[100px] mx-auto" />
                                    </td>

                                    <td className="flex justify-center items-center gap-2 py-14">
                                        <EditeService id={item.id} api={ApiLink} />

                                        <DeleteDataComponent id={item.id} api={ApiLink} section={"servicesection"} endpoint={"deleteservie"} />


                                    </td>
                                </tr>
                            ))
                        }


                    </tbody>
                </table>
            </div>

        </section>
    )
}
