import axios from 'axios';
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'

export default function EditeWhyChoose({ id, api }) {
    const [isopen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [icon, setIcon] = useState('');
    const [imagefile, setImageFile] = useState('');



    useEffect(() => {
        fetch(`${api}/whysection/showdata/${id}`)
            .then((res) => res.json())
            .then((data) => {
                setTitle(data.title);
                setDesc(data.description);
            })
    }, [])




    async function SendDataHandlling(e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', desc);
        formData.append('icon', icon);
        formData.append('image', imagefile);

        try {
            const sendres = await axios.post(`${api}/whysection/updatedata/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('تم الإرسال بنجاح');

        } catch (error) {

            toast.error('فشل الإرسال');
        }
    }
    return (
        <section className='relative'>
            <button
                data-modal-target="default-modal"
                data-modal-toggle="default-modal"
                className="block text-white bg-[#00BF62]   font-medium rounded-lg text-sm px-5 py-2.5 "
                type="button"
                onClick={() => setIsOpen(true)}
            >

                تعديل
            </button>


            <div id="default-modal"
                tabIndex="-1"
                aria-hidden="true"
                className={`${isopen ? 'block' : 'hidden'} fixed  overflow-y-auto overflow-x-hidden mx-[35%] my-[10%] z-50 justify-center items-center w-[50%] md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-[#212121] rounded-lg shadow ">

                        <div className="flex items-center justify-between p-4 md:p-5 border-[#ffffff4a] border-b rounded-t">
                            <h3 className="text-xl font-semibold text-[#00BF62]  ">
                                اضف
                            </h3>
                            <button
                                type="button"
                                className="text-white bg-transparent  w-8 h-8 ms-auto inline-flex justify-center items-center"
                                data-modal-hide="default-modal"
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>

                            </button>
                        </div>

                        <form className="p-4 md:p-5 space-y-4" onSubmit={SendDataHandlling}>

                            <div>
                                <label className="text-white text-lg">Title</label>
                                <input
                                    type="text"
                                    required
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className='w-full h-[40px] resize-none bg-white rounded-sm mx-auto  focus:outline-none placeholder:pr-5' />
                            </div>
                            <div>
                                <label className="text-white text-lg">Description</label>
                                <input
                                    type="text"
                                    required
                                    value={desc}
                                    onChange={(e) => setDesc(e.target.value)}
                                    className='w-full h-[40px] resize-none bg-white rounded-sm mx-auto  focus:outline-none placeholder:pr-5' />
                            </div>


                            <label className="block mb-2 text-lg  text-white" htmlFor="multiple_files">icon</label>
                            <input

                                className='w-full h-[40px] bg-white rounded-sm mx-auto pt-[5px] pr-3  focus:outline-none'
                                id="multiple_files"
                                type="file"
                                onChange={(e) => setIcon(e.target.files[0])}
                                multiple
                            />

                            <label className="block mb-2 text-lg  text-white" htmlFor="multiple_files">image</label>
                            <input

                                className='w-full h-[40px] bg-white rounded-sm mx-auto pt-[5px] pr-3  focus:outline-none'
                                id="multiple_files"
                                type="file"
                                onChange={(e) => setImageFile(e.target.files[0])}
                                multiple
                            />


                            <div className='my-10'>
                                <div className="w-[200px] h-[30px]  mx-auto">
                                    <button type='submit' className='w-full h-full rounded-md bg-[#00BF62] text-white font-semibold'>
                                        ارسال الداتا
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </section>
    )
}
