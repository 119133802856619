import axios from "axios";
import { useState } from "react"
import toast, { Toaster } from "react-hot-toast";

const Api_Url = 'https://api.digitall-minds.com/api';
export default function AdminSection() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');
    const [accept, setAccept] = useState(false)
    const [error, setError] = useState(false);


    async function FormLoginHandelling(e) {
        e.preventDefault();

        let flag = true;

        setAccept(true);
        if (name === "" || password.length < 8 || repassword !== password) {
            flag = false;
        } else flag = true;
        try {
            if (flag) {
                let res = await axios.post(`${Api_Url}/register`, {
                    name: name,
                    email: email,
                    password: password,
                    password_confirmation: repassword,
                });
                toast.success('تم ادمن ');
            }
        } catch (err) {
            setError(err.response.status);
        }
    }



    return (
        <section>
            <form className='w-[50%] lg:w-[600px] h-[550px] mx-auto rounded-md shadow bg-[#212121] my-[5%]' onSubmit={FormLoginHandelling}>
                <h1 className='text-center pt-5 text-2xl font-semibold text-[#00BF62]'>انشاء ادمن</h1>
                <div className='w-[80%] mx-auto pt-10'>
                    <div className='py-5'>
                        <input type='text'
                            placeholder='الاسم'
                            name={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className='w-full h-[40px] bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5' />
                        {
                            name.length < 2 && accept && (<p className="text-red-600">
                                يرجي التأكد من الاسم
                            </p>)
                        }
                    </div>
                    <div className='py-5'>
                        <input type='email'
                            placeholder='البريد الالكتروني'
                            name={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className='w-full h-[40px] bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5' />
                        {
                            accept && error && (
                                <p className="text-red-600">
                                    الايميل مستخدم بالفعل
                                </p>
                            )
                        }
                    </div>
                    <div className='py-5'>
                        <input type='password'
                            placeholder='الرقم السري'
                            name={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className='w-full h-[40px] bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5' />
                        {
                            password === '' ? (<p className="text-yellow-300">الرقم السري يتكون من اكثر من 8 احرف</p>) : (<></>)
                        }
                        {
                            password.length < 8 && accept && (<p className="text-red-600">
                                يجب ان يكون 8 احرف
                            </p>)
                        }
                    </div>
                    <div className='py-5'>
                        <input type='password'
                            placeholder='اعاده كتابه الرقم السري'
                            name={repassword}
                            onChange={(e) => setRePassword(e.target.value)}
                            required
                            className='w-full h-[40px] bg-white rounded-sm mx-auto  focus:outline-none placeholder:pr-5' />
                        {
                            password !== repassword && accept && (
                                <p className="text-red-600">
                                    الرقم السري غير ممطابق
                                </p>
                            )
                        }
                    </div>

                    <div className='my-10'>
                        <div className="w-[200px] h-[30px]  mx-auto">
                            <button type='submit' className='w-full h-full rounded-md bg-[#00BF62] text-white font-semibold'>
                                انشاء
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </section>
    )
}
