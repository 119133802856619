import { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
    const [auth, setAuth] = useState(true);

    useEffect(() => {

        const email = localStorage.getItem("email");
        if (email) {
            setAuth(true);
        } else {
            setAuth(false);
        }
    }, []);


    if (auth === null) {
        return <div>Loading...</div>;
    }

    return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
