import axios from "axios";
import { useEffect, useState } from "react"
import toast, { Toaster } from "react-hot-toast";

const Api_Url = 'https://api.digitall-minds.com/api';
export default function HeroSection() {


  const [contant, setContant] = useState('');
  const [link, setLink] = useState('');


  const [reapt, setReapt] = useState(true)

  useEffect(() => {
    fetch(`${Api_Url}/herosection/showtext/1`).then((res) => res.json()).then((data) => {
      setContant(data.title)
      setLink(data.link)
    })
  }, [reapt])

  async function UpdateHandllingForm(e) {
    e.preventDefault();

    const res = await axios.put(`${Api_Url}/herosection/updatetext/1`, {
      title: contant,
      link: link,

    })
    setReapt(!reapt)
    toast.success('تم الارسال بنجاح');

  }

  return (
    <section>
      <form className='w-[50%]  h-full pb-1 mx-auto rounded-md shadow bg-[#212121] my-[5%]' onSubmit={UpdateHandllingForm}>
        <h1 className='text-center pt-5 text-2xl font-semibold text-[#00BF62]'>الصفحه الرئيسيه</h1>
        <div className='w-[80%] mx-auto pt-10'>
          <label className="text-white">محتوي الصفحه الرئيسيه</label>
          <div className='py-2'>
            <textarea
              placeholder='اضف المحتوي'
              required
              name={contant}
              value={contant}
              onChange={(e) => setContant(e.target.value)}
              className='w-full h-[100px] resize-none bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5'>
            </textarea>
          </div>

          <label className="text-white">لينك للتواصل</label>
          <div className='py-2'>
            <input
              type="text"
              placeholder='اضف لينك الواتساب'
              name={link}
              value={link}
              onChange={(e) => setLink(e.target.value)}
              required
              className='w-full h-[40px] resize-none bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5' />
          </div>


          <div className='my-10'>
            <div className="w-[200px] h-[30px]  mx-auto">
              <button type='submit' className='w-full h-full rounded-md bg-[#00BF62] text-white font-semibold'>
                ارسال الداتا
              </button>
            </div>
          </div>
        </div>
      </form>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </section>
  )
}
