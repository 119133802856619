import React from 'react'
import Navebar from '../components/Navebar'
import { Outlet } from 'react-router-dom'

export default function Dashboard() {
    return (
        <div className='flex justify-center items-center'>
            <div className='w-[20%] h-full'>
                <Navebar />
            </div>
            <div className='w-[75%] mt-[5%] mx-auto h-full'>
                <Outlet />
            </div>

        </div>
    )
}
