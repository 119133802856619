import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import PrivateRoutes from './utils/PrivateRoutes'
import Dashboard from './pages/Dashboard';
import HeroSection from './pages/HeroSection';
import AdminSection from './pages/AdminSection';
import AboutSection from './pages/AboutSection';
import ServiceSection from './pages/ServicePage/ServiceSection';

import WhyChoose from './pages/Why_Choose/WhyChoose';
import MetaTags from './pages/MetaTags';
import CommentShow from './pages/Comments/CommentShow';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<PrivateRoutes />}>
            <Route element={<Dashboard />} path='/'>
              <Route path='admincreate' element={<AdminSection />} />
              <Route path='metatags' element={<MetaTags />} />
              <Route path='herosection' element={<HeroSection />} />
              <Route path='aboutsection' element={<AboutSection />} />
              <Route path='servicesetion' element={<ServiceSection />} />
              <Route path='whychoosesection' element={<WhyChoose />} />
              <Route path='comment' element={<CommentShow />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
