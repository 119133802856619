import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'



const Api_Url = 'https://api.digitall-minds.com/api';
export default function AboutSection() {

    const [contant, setContant] = useState('');


    const [reapt, setReapt] = useState(true)

    useEffect(() => {
        fetch(`${Api_Url}/aboutsection/showtext/1`)
            .then((res) => res.json())
            .then((data) => {
                setContant(data.title)
            })
    }, [reapt])

    async function UpdateHandllingForm(e) {
        e.preventDefault();

        const res = await axios.put(`${Api_Url}/aboutsection/updatetext/1`, {
            title: contant
        })
        setReapt(!reapt)
        toast.success('تم الارسال بنجاح');

    }


    return (
        <section>
            <form className='w-[50%] h-full pb-1 mx-auto rounded-md shadow bg-[#212121] my-[5%]' onSubmit={UpdateHandllingForm}>
                <h1 className='text-center pt-5 text-2xl font-semibold text-[#00BF62]'>من نحن</h1>
                <div className='w-[80%] mx-auto pt-10'>
                    <label className="text-white">محتوي صفحه من نحن </label>
                    <div className='py-2'>
                        <textarea
                            placeholder='اضف المحتوي'
                            required
                            value={contant}
                            onChange={(e) => setContant(e.target.value)}
                            className='w-full h-[100px] resize-none bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5'>
                        </textarea>
                    </div>



                    <div className='my-10'>
                        <div className="w-[200px] h-[30px]  mx-auto">
                            <button type='submit' className='w-full h-full rounded-md bg-[#00BF62] text-white font-semibold'>
                                ارسال الداتا
                            </button>
                        </div>
                    </div>
                </div>
            </form>


            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </section>
    )
}
