import { Link } from 'react-router-dom'
import Logo from '../image/Logo.svg'

const Links = [
    { title: 'انشاء ادمن', url: "/admincreate" },
    { title: 'ميتا تاج', url: "/metatags" },
    { title: 'صفحه رئيسيه', url: "/herosection" },
    { title: 'صفحه من نحن', url: "/aboutsection" },
    { title: 'صفحه الخدمات ', url: "/servicesetion" },
    { title: 'لماذا تختار ديجيتال', url: "/whychoosesection" },
    { title: 'الكومنتات', url: "/comment" },

]

export default function Navebar() {







    return (
        <nav>
            <aside id="default-sidebar" className="fixed top-0 right-0 z-40 w-64 h-screen transition-transform " aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto bg-[#212121] ">
                    <div className='border-b-2 border-[#ffffff2f] py-5'>
                        <Link to={"/"}>
                            <div className='w-[80%] h-[80px] mx-auto'>
                                <img src={Logo} className='w-full h-full' alt='Logo' />
                            </div>
                        </Link>
                    </div>
                    <ul className="space-y-2 font-medium mt-5">
                        {
                            Links.map((item, index) => (
                                <div className='border-b-2 h-[50px] border-[#ffffff2f]' key={index}>
                                    <li className='text-center text-[20px]  text-white py-1  transform duration-300 rounded-md hover:bg-[#00BF62]' >
                                        <Link to={item.url}>
                                            {item.title}
                                        </Link>
                                    </li>
                                </div>

                            ))
                        }
                        <div className='w-[90%] mx-auto'>
                            <button className='w-full py-1 rounded-md bg-red-600 text-white text-center' onClick={() => {
                                localStorage.removeItem("email");
                                window.location.reload();
                            }}>
                                خروج
                            </button>
                        </div>
                    </ul>
                </div>
            </aside>
        </nav>
    )
}
