import axios from "axios";
import { useEffect, useState } from "react"
import toast, { Toaster } from "react-hot-toast";

const Api_Url = 'https://api.digitall-minds.com/api';

export default function MetaTags() {
    const [title_seo, setTitel_Seo] = useState('');
    const [description_seo, setDescription_Seo] = useState('');
    const [keywords_seo, setKeywords_Seo] = useState('');

    const [reapt, setReapt] = useState(true)

    useEffect(() => {
        fetch(`${Api_Url}/seo/showseo/1`).then((res) => res.json()).then((data) => {
            setTitel_Seo(data.title_seo)
            setDescription_Seo(data.description_seo)
            setKeywords_Seo(data.keywords_seo)
        })
    }, [reapt])

    async function UpdateHandllingForm(e) {
        e.preventDefault();

        const res = await axios.post(`${Api_Url}/seo/updateseo/1`, {
            title_seo: title_seo,
            description_seo: description_seo,
            keywords_seo: keywords_seo,
        })
        setReapt(!reapt)
        toast.success('تم الارسال بنجاح');

    }

    return (
        <section>
            <form className='w-[50%]  h-full pb-1 mx-auto rounded-md shadow bg-[#212121] my-[5%]' onSubmit={UpdateHandllingForm}>
                <h1 className='text-center pt-5 text-2xl font-semibold text-[#00BF62]'>Meta Tags</h1>
                <div  className='w-[80%] mx-auto pt-10'>
               

                    <label className="text-white">title (seo)</label>
                    <div className='py-2'>
                        <input
                            type="text"
                            placeholder='title (seo)'
                        
                            value={title_seo}
                            onChange={(e) => setTitel_Seo(e.target.value)}
                            required
                            className='w-full h-[40px] resize-none bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5' />
                    </div>

                    <label className="text-white">Desccription (seo)</label>
                    <div className='py-2'>
                        <input
                            type="text"
                            placeholder='Desccription (seo)'
                   
                            value={description_seo}
                            onChange={(e) => setDescription_Seo(e.target.value)}
                            required
                            className='w-full h-[40px] resize-none bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5' />
                    </div>

                    <label className="text-white">Keywords (seo)</label>
                    <div className='py-2'>
                        <input
                            type="text"
                            placeholder='Desccription (seo)'
                 
                            value={keywords_seo}
                            onChange={(e) => setKeywords_Seo(e.target.value)}
                            required
                            className='w-full h-[40px] resize-none bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5' />
                    </div>

                    <div className='my-10'>
                        <div className="w-[200px] h-[30px]  mx-auto">
                            <button type='submit' className='w-full h-full rounded-md bg-[#00BF62] text-white font-semibold'>
                                ارسال الداتا
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </section>
    )
}
