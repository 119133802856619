import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


const Api_Url = 'https://api.digitall-minds.com/api';

const Login = () => {


  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState('')

  const Navigate = useNavigate()

  async function FormLoginHandelling(e) {
    e.preventDefault();

    try {
      const res = await axios.post(`${Api_Url}/login`, {
        email: email,
        password: password
      })
      localStorage.setItem("email", email);
      Navigate('/');
    }
    catch (error) {
      setError(error.response.status)
    }
  }

  console.log(error)
  return (
    <section>
      <form className='w-[50%] lg:w-[600px] h-[400px] mx-auto rounded-md shadow bg-[#212121] my-[10%]' onSubmit={FormLoginHandelling}>
        <h1 className='text-center pt-5 text-2xl font-semibold text-[#00BF62]'>سجل دخولك</h1>
        <div className='w-[80%] mx-auto pt-10'>
          <div className='py-5'>
            <input type='email'
              placeholder='البريد الالكتروني'
              name={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className='w-full h-[40px] bg-white rounded-sm mx-auto   focus:outline-none placeholder:pr-5' />
            {error === 422 ? <span className="text-red-600 ">يرجي التأكد من الايميل المدخل</span> : ""}
          </div>
          <div className='py-5'>
            <input type='password'
              placeholder='الرقم السري'
              name={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className='w-full h-[40px] bg-white rounded-sm mx-auto  focus:outline-none placeholder:pr-5' />
            {error === 422 ? <span className="text-red-600 ">يرجي التأكد من كلمه السر المدخله</span> : ""}
          </div>
          <div className="w-[200px] h-[30px] mt-5 mx-auto">
            <button type='submit' className='w-full h-full rounded-md bg-[#00BF62] text-white font-semibold'>
              دخول
            </button>
          </div>

        </div>
      </form>
    </section>
  );
};

export default Login;
