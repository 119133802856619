import { useEffect, useState } from "react";
import DeleteDataComponent from "../../components/DeleteDataComponent";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Api_Url = 'https://api.digitall-minds.com/api';
export default function CommentShow() {

    const [comment, setComment] = useState([]);

    useEffect(() => {
        fetch(`${Api_Url}/commentssetion/showdatadashboard`)
            .then((res) => res.json())
            .then((data) => { setComment(data) })
    }, [])


    function handllingapprovedcomment(id) {

        const response = axios.put(`${Api_Url}/commentssetion/approvecomment/${id}`, {
            isapproved: false,
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success('تم التأكيد');
                }
            })
            .catch((error) => {
                toast.error('فشل التأكيد');
            });
    }


    return (
        <section >
            <h1 className="text-[30px] text-[#00BF62] text-center font-bold">
                الكومنتات
            </h1>

            <table className="w-full text-[16px] text-center  text-gray-500 mt-10">
                <thead className="text-white uppercase bg-[#00BF62] ">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            id
                        </th>
                        <th scope="col" className="px-6 py-3">
                            الاسم
                        </th>
                        <th scope="col" className="px-6 py-3">
                            الكومنت
                        </th>
                        <th scope="col" className="px-7 py-3">
                            حاله الكومنت
                        </th>
                        <th scope="col" className="px-6 py-3">

                        </th>
                    </tr>
                </thead>

                <tbody>
                    {
                        comment.map((item, index) => (
                            <tr className="bg-[#212121] border-[#ffffff4a] border-b-2" key={index}>
                                <th scope="row" className=" py-4 font-medium text-white whitespace-nowrap">
                                    {index + 1}
                                </th>
                                <th scope="row" className=" py-4 font-medium text-white ">
                                    {item.name}
                                </th>
                                <th scope="row" className=" py-4 font-medium text-white ">
                                    <p className="w-[70%] text-center mx-auto">
                                        {item.comments}
                                    </p>
                                </th>
                                <th scope="row" className=" py-4  font-medium text-white ">
                                    {item.isapproved === 1 ?
                                        (<div className="w-[60px] mx-auto bg-[#00BF62] rounded-md text-center">
                                            نشط
                                        </div>)
                                        :
                                        (<div className="w-[60px] mx-auto bg-red-700 rounded-md text-center">
                                            غير نشط
                                        </div>)}
                                </th>
                                <th className="flex justify-center items-center  gap-2 py-5 pl-5">
                                    <div className="w-[100px] h-[40px]">
                                        <button className="bg-[#00BF62] h-full w-full  rounded-md text-white" onClick={() => handllingapprovedcomment(item.id) }>
                                            Approve
                                        </button>
                                    </div>

                                    <DeleteDataComponent id={item.id} api={Api_Url} section={"commentssetion"} endpoint={"deletedata"} />
                                </th>
                            </tr>

                        ))

                    }


                </tbody>
            </table>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </section>

    )
}
