import axios from 'axios';
import Swal from 'sweetalert2';

export default function DeleteDataComponent({ id, api, section, endpoint }) {
    function handleDeleteClick(ItemId) {
        Swal.fire({
            title: "هل انت متأكد من حذف العنصر",
            icon: "warning",
            showCancelButton: true,
            CancelButtonText: "الغاء",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${api}/${section}/${endpoint}/${ItemId}`)

                Swal.fire({
                    title: "تم الحذف بنجاح!",
                    icon: "success"
                });
            }
        });

    }


    return (
        <div>
            <button
                type="button"
                className="focus:outline-none text-white bg-red-700 font-medium rounded-lg text-sm px-2 py-2"
                onClick={() => handleDeleteClick(id)}
            
            >
                حذف
            </button>
        </div>
    );
}
